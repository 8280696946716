<template>
	<div class="NewAftermarket" v-if="OrderDetail != null">
			
		<div class="AppealInfo">
			<h3>
				<span>
					申诉编号:{{Appeal.Id}}
				</span>
				<label>
					申诉日期:{{Appeal.CreatedAt}}
				</label>
			</h3>
			
			<div class="BaseInfo">
				
				<div class="AppealBase">
					<ul>
						<li><span>申诉状态:</span>
						
						<label>{{Appeal.StatusName}}
						
						<em style="margin-left: 10px;">
						<el-button size="mini" v-if="Appeal.Status == 60" @click="ExpressDialog = true" type="primary">提交退货快递单</el-button>
						<el-button size="mini" type="danger" @click="CancelCheck()">取消申诉</el-button>
						</em>
						
						</label></li>
						<li><span>申诉类型:</span><label>{{Appeal.AppealTypeName}}</label></li>
						<li><span>申诉原因:</span><label>{{Appeal.AppealReasonName}}</label></li>
						<li><span>售后数量:</span><label>{{Appeal.AppealNum}}</label></li>
						<li><span>退款金额:</span><label>{{Appeal.AppealRefund}}</label></li>
					</ul>
					<ul>
						<li>
							<span>补充描述:</span>
							<label>{{Appeal.AppealDetail}}</label>
						</li>
					</ul>
				</div>
				
				<div class="OrderInfo">
					<ul class="Left">
						<img :src="OrderDetail.GoodsThumb" />
					</ul>
					<ul class="Right">
						<h3 style="margin-bottom:10px;padding-bottom: 10px;">{{OrderDetail.GoodsName}}</h3>
						<li>单价:<span>{{OrderDetail.Price}}</span>购买数量:<span>{{OrderDetail.Num}}</span>费用小计:<span>{{OrderDetail.TotalFee}}</span>规格:<span>{{OrderDetail.SkuName}}</span></li>
					</ul>
				</div>
				
			</div>
			
			
		</div>
		
		<!--申诉交流-->
		<div class="AppealContract">
			<h3>
				申诉交流记录
			</h3>
			<div class="Contract" v-for="contract in ContractList" :key="contract.Id">
				<h4>
					<span>
						{{contract.RoleName}}
					</span>
					<label>
						{{contract.CreatedAt}}
					</label>
				</h4>
				<p><span style="color: rgba(0,0,0,0.4);margin-right: 10px;">交流内容</span>:{{contract.Detail}}</p>
				<div class="ProofImages" v-if="contract.Images.length > 0">
					
					<a :href="proof" target="_blank" v-for="(proof,proofI) in contract.Images" :key="proofI">
						<img :src="proof" />
					</a>
				</div>
				
			</div>
		</div>
		
		<div class="NewContract">
			
			<h4 style="padding: 0px 0px 10px 0px;margin: 0px 0px 10px 0px;border-bottom: 1px solid rgba(0,0,0,0.04);">新建申诉信息</h4>
			<div>
				<li>
					<span>新的留言:</span>
					<em>
						<el-input type="textarea" size="mini" style="width: 350px;"  v-model="NewContract.Detail"></el-input>
					</em>
				</li>
				
				<li>
					<span>图片凭据:</span>
					<em style="display: flex;">
						<div class="PreviewImg" v-for="(item,itemI) in NewContract.Images" :key="itemI">
							<div class="ShowContent">
								<img :src="item" class="avatar">
							</div>
							<i class="Remove" @click="removePicWall(itemI)">移除</i>
						</div>
						
						<el-upload v-if="ProofImages.length < 5"
						  action="https://jsonplaceholder.typicode.com/posts/"
						  accept="image/jpeg,image/jpg,image/gif,image/png"
						  :show-file-list="false" :on-change="getFilesChange" :limit="5" :auto-upload="false">
							<div class="PreviewImg">
								<div class="ShowContent">
									<i  class="el-icon-plus"></i>
								</div>
							</div>
						</el-upload>
					</em>
				</li>
				
				<li>
					
					<span></span>
					
					<el-button type="primary" @click="NewAppealContract()">提交</el-button>
				
					
				</li>
			</div>
		</div>
		
		
		<el-dialog
		  title="快递/物流退货单号录入"
		  :visible="ExpressDialog"
		  width="600px"
		  :before-close="ExpressDialogClose">
		  
		  <div class="ExpressDialogForm">
			  <li>
				  <span>
					  快递/物流公司
				  </span>
				  
				  <em style="width: 200px;">
					  <el-select
					      v-model="ExpressCompanyCode"
						  size="medium"
					      filterable
					      remote
					      reserve-keyword
					      placeholder="请选择快递公司"
					      :remote-method="ExpressCompanyRemote"
					      :loading="ExpressCompanyRemoteLoading">
					      <el-option
					        v-for="(item,itemI) in ExpressCompanyOpts"
					        :key="itemI"
					        :label="item.Name"
					        :value="item.Code">
					      </el-option>
					    </el-select>
				  </em>
				  
				  <label style="color: rgba(0,0,0,0.4);">输入关键词可检索国内外快递公司</label>
					
			  </li>
			  
			  
			  <li>
				  <span>
					  快递/物流单号
				  </span>
				  <em>
					<el-input size="medium" v-model="ExpressCode"></el-input>
				  </em>
			  					
			  </li>
			  
		  </div>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="ExpressDialog = false">取 消</el-button>
		    <el-button type="primary" @click="SetExpress()">确 定</el-button>
		  </span>
		</el-dialog>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Upload,Checkbox,InputNumber,Dialog} from 'element-ui'
	export default {
	  name: 'NewAftermarketNew',
	  props: {
	  },
	  data() {
	      return {
			  OrderDetail:null,
			  Appeal:{
				Id:'',
				OrderId:'',
				OrderDetailId:'',
				ShopId:'',
				ExpressCompanyName:'',
				ExpressCompanyCode:'',
				ExpressCode:'',
				AppealType:'',//申请类型  repair维修 change换货 refund 退款
				AppealReason:'', //申诉原因类型 货物未收到unreceived 货物发错 wronggoods 货物质量差badgoods 货物数量不对wrongnum 买家个人原因personal
				AppealDetail:'', //申诉描述详情
				AppealImages:[], //申诉图片列表
				AppealRefund:0.00, //申请退款金额
				AppealNum:1, //售后商品数量
				CheckRefund:0.00, //确认的退款金额 可能来自商家/系统客服
				CheckNum:0, //确认的商品数量 可能来自商家/系统客服
				RefuseReason:'', //拒绝理由
				RefuseImages:[],//拒绝图片列表
			  },
			  ContractList:[],//获取申诉交流记录
			  ProofImages:[],//新的图片凭据
			  NewContract:{
				  Images:[],//新的图片凭据
				  Num:0,//新的数量
				  Refund:0.00,//新的退款金额,
				  Detail:'',//交流详情
			  },
			  ExpressCode:'',//发货单号
			  ExpressDialog:false,
			  ExpressCompanyCode:'', //发货快递/物流公司
			  ExpressCompanyRemoteLoading:false,
			  ExpressCompanyOpts:[],
			  RecommendExpressCompanyOpts:[],//推荐快递公司只需加载一次
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-upload':Upload,
		'el-checkbox':Checkbox,
		"el-input-number":InputNumber,
		'el-dialog':Dialog
	  },
	  created() {
	  	this.GetAppeal()
	  },
	  methods:{
		  removePicWall(_index){
		  	this.NewContract.Images.splice(_index,1)
		  },
		  GetOrderDetail(_detailId){ //获取子订单详情
			  let data = {
			  	Service:'Order',
			  	Class: 'Order',
			  	Action: 'SonOrderDetail',
			  	Id:_detailId
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
			  	this.OrderDetail = res.Data
				this.Appeal.OrderId = this.OrderDetail.OrderId
				this.Appeal.OrderDetailId = this.OrderDetail.Id
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  GetAppeal(){
			let data = {
				Service:'Order',
				Class: 'Appeal',
				Action: 'Get',
				Id:this.$route.params.Id
			}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}

				this.Appeal = res.Data
				this.GetOrderDetail(this.Appeal.OrderDetailId)
				this.GetAppealContractList()
				
			})
			.catch(function (response) {
				this.$message('网络请求错误')
			})
		  },
		  GetAppealContractList(){
		  			let data = {
		  				Service:'Order',
		  				Class: 'Appeal',
		  				Action: 'ContractList',
		  				Id:this.$route.params.Id
		  			}
		  			this.$post(this.$store.getters.getApiHost,data)
		  			.then((res) => {
		  				
		  				if(res.ErrorId != 0){
		  					this.$message(res.Msg)
		  					return
		  				}
						
						if(res.Data.AppealContractList == null || res.Data.AppealContractList == undefined){
							this.ContractList = []
							return
						}
						
		  				this.ContractList = res.Data.AppealContractList
		  				
		  			})
		  			.catch(function (response) {
		  				this.$message('网络请求错误')
		  			})
		  },
		  getFilesChange(file, fileList) {
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		  },
		  getFileChange(file, fileList) {
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		  },
		  getBase64(file) {
			return new Promise(function(resolve, reject) {
			  let reader = new FileReader();
			  let imgResult = "";
			  reader.readAsDataURL(file);
			  reader.onload = function() {
				imgResult = reader.result;
			  };
			  reader.onerror = function(error) {
				reject(error);
			  };
			  reader.onloadend = function() {
				resolve(imgResult);
			  };
			});
		  },
		  uploadPic(_b64){
			  let data = {
				Service:'Goods',
				Class: 'File',
				Action: 'Base64Upload',
				Base64:_b64
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				if(this.NewContract.Images.length < 5){ //如果图墙未满，则将主图添加到图墙
					this.NewContract.Images.push(res.Data.Url)
				}
				
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  },
		  NewAppealContract(){ //提交新的申诉交流信息
			  let data = {
				Service:'Order',
				Class: 'Appeal',
				Action: 'NewContract',
				Id:this.Appeal.Id,
				Detail:this.NewContract.Detail,
				ProofImages:JSON.stringify(this.NewContract.Images),
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message.error(res.Msg)
					return
				}
				this.$message(res.Msg)
				this.NewContract.Detail = ''
				this.NewContract.Images = []
				this.GetAppealContractList()
				
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  },
		  CancelCheck(){
			this.$confirm('是否确认撤销申诉？')
			.then(() => {
			  this.Cancel()
			})  
		  },
		  Cancel(){ //撤销申诉
			  let data = {
				Service:'Order',
				Class: 'Appeal',
				Action: 'Cancel',
				Id:this.Appeal.Id,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				this.$message(res.Msg)
				if(res.ErrorId != 0){
					return
				}
				
				this.GetAppeal()
				
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  },
		  ExpressDialogClose(){
		  			  this.ExpressDialog = false
		  },
		  ExpressDialogOpen(){ //初始化快递公司信息，默认载入前X个知名公司
		  				this.ExpressDialog = true
		  				if(this.RecommendExpressCompanyOpts.length == 0){
		  					this.GetRecommendExpressCompanyOpts()
		  				}
		  },
		  GetRecommendExpressCompanyOpts(){
		  			  let data = {
		  			  	Service:'Order',
		  			  	Class: 'ExpressCompany',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:50,
		  			  	IsRecommend:1
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  		for(var i=0;i<res.Data.ExpressCompanyList.length;i++){
		  			  				
		  			  			this.ExpressCompanyOpts.push({
		  			  				Code:res.Data.ExpressCompanyList[i].Code,
		  			  				Name:res.Data.ExpressCompanyList[i].Name,
		  			  			})
		  			  		}
		  			  		this.RecommendExpressCompanyOpts = JSON.parse(JSON.stringify(this.ExpressCompanyOpts))
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  		this.$message('网络请求快递信息错误')
		  			  })
		  },
		  ExpressCompanyRemote(_keyWords){
		  			  //去除首尾空格
		  			  _keyWords = this.$Trim(_keyWords)
		  			  if(_keyWords.length == 0){
		  				  this.ExpressDialogOpen()
		  				  return
		  			  }
		  			  this.ExpressCompanyRemoteLoading = true
		  			  let data = {
		  			  	Service:'Order',
		  			  	Class: 'ExpressCompany',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:20,
		  			  	Name:_keyWords
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	this.ExpressCompanyRemoteLoading = false
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  				
		  				this.ExpressCompanyOpts = []
		  			  	for(var i=0;i<res.Data.ExpressCompanyList.length;i++){
		  		
		  					this.ExpressCompanyOpts.push({
		  						Code:res.Data.ExpressCompanyList[i].Code,
		  						Name:res.Data.ExpressCompanyList[i].Name,
		  					})
		  				}
		  				
		  			  	
		  			  })
		  			  .catch(function (response) {
		  					this.ExpressCompanyRemoteLoading = false
		  					this.ExpressDialogOpen()
		  					this.$message('网络请求错误')
		  			  })
		  },
		  SetExpress(){
		  			  let data = {
		  				Service:'Appeal',
		  				Class: 'Appeal',
		  				Action: 'UpExpress',
		  				Id:this.Appeal.Id,
		  				ExpressCompanyCode:this.ExpressCompanyCode,
		  				ExpressCode:this.$Trim(this.ExpressCode),
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  				
		  				
		  				if(res.ErrorId != 0){
		  					this.$message.error(res.Msg)
		  					return
		  				}
		  				
		  				this.$message('快递单录入成功')
		  				this.ExpressDialog = false
		  				this.GetAppeal()
		  				
		  			  })
		  			  .catch(function (response) {
		  					this.$message('网络请求快递信息错误')
		  			  })
		  },
		
	  }
	}
</script>

<style scoped>
	.NewAftermarket{
		margin: 20px;
		background-color: #FFFFFF;
		padding: 20px;
	}
	
	.PreviewImg{
		display: inline-block;
		width: 100px;
		height: 100px;
		border:1px dotted rgba(0,0,0,0.1);
		position: relative;
		margin-right: 5px;
	}
	.PreviewImg:hover{
		border:1px dotted rgba(228,0,0,0.7);
	}
	.PreviewImg i.Remove{
		display: none;
	}
	.PreviewImg:hover i.Remove{
		display: inline-block;
		position: absolute;
		bottom: 0px;
		right: 0px;
		background-color: rgba(228,0,0,0.8);
		padding: 2px 5px;
		border-radius: 2px;
		color: #FFFFFF;
		cursor: pointer;
		font-style: normal;
	}
	.PreviewImg .ShowContent{
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	}
	.PreviewImg .ShowContent img{
		width: 90%;
		height: 90%;
	}
	
	.BaseInfo{
		display: flex;
		padding: 20px 0px;
	}
	.AppealInfo{
		flex: 1;
	}
	.AppealInfo h3{
		display: flex;
		font-weight: normal;
		align-items: center;
		border-bottom: 1px solid rgba(0,0,0,0.05);
		padding: 10px 0px;
		margin: 0px;
	}
	
	.AppealInfo h3 span{
		flex: 1;
	}
	.AppealInfo h3 label{
		font-size: 0.9rem;
		color: rgba(0,0,0,0.4);
	}
	
	.AppealBase{
		background-color:rgba(255,94,97,0.15);
		flex: 1;
		padding: 20px;
	}
	.AppealBase ul{
		width: 100%;
	}
	.AppealBase ul li{
		flex: 1;
		margin-bottom: 10px;
		align-items: center;
	}
	.AppealBase ul li span{
		margin-right: 10px;
		color: rgba(0,0,0,0.4);
	}
	.AppealBase ul li label{
		font-size: 1.1rem;
	}
	
	.OrderInfo{
		display: flex;
		flex: 1;
		padding: 20px;
		background-color: rgba(0,0,0,0.04);
	}
	.OrderInfo h3{
		display: block;
		margin: 0px;
		padding: 0px;
		width: 100%;
	}
	.OrderInfo .Left{
		text-align: center;
		margin-right: 10px;
	}
	.OrderInfo .Left img{
		width: 100px;
		height: 100px;
	}
	.OrderInfo .Right{
		flex: 1;
	}
	.OrderInfo .Right li{
		color: #999999;
	}
	.OrderInfo .Right li span{
		color: rgba(228,0,0,1);
		margin: 0px 10px;
	}
	
	.AppealContract{
		
	}
	.AppealContract h3{
		padding: 15px 0px;
		border-bottom: 1px dotted rgba(0,0,0,0.2);
		margin: 0px;
	}
	.Contract{
		padding:10px 20px;
		border-bottom: 1px dotted rgba(0,0,0,0.2);
	}
	.ProofImages{
		display: flex;
		align-items: center;
	}
	.ProofImages a {
		margin-right: 10px;
	}
	.ProofImages a img{
		max-width: 120px;
		max-height: 60px;
	}
	
	.NewContract{
		background-color: rgba(0,0,0,0.04);
		padding: 20px;
		margin-top: 20px;
	}
	.NewContract div li{
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		align-items: flex-start;
	}
	.NewContract div li span{
		width: 90px;
		color: rgba(0,0,0,0.4);
	}
	.NewContract div li em{
	}
	
	.ExpressDialogForm{
		
	}
	.ExpressDialogForm li{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	.ExpressDialogForm li span{
		width: 120px;
	}
	.ExpressDialogForm li em{
		display: block;
		width: 300px;
	}
	.ExpressDialogForm li label{
		color: rgba(228,0,0,1);
		margin-left: 5px;
		font-size: 0.9rem;
	}
</style>
